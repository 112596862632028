import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  toggle(event) {
    let target = event.target;
    let parent = target.parentElement;
    if ( target.classList.contains('manager') ) {
      target.classList.toggle('rotate-0');
      target.classList.toggle('-rotate-180');
    }
    if ( parent.classList.contains('manager') ) {
      parent.classList.toggle('rotate-0');
      parent.classList.toggle('-rotate-180');
    }
  }
}