import { Controller } from "@hotwired/stimulus"
import "ace-builds";

export default class extends Controller {

  htmlEditor = null;
  ckEditor = null;
  currentTab = 'rich';

	static targets = [
    'modalPreview',
    'modalPreviewContent',
    'seoURL'
  ];

  static values = {
    lang: String
  };

  connect() {

    //
    // Loading ACE editor and initializing
    //

    this._loadAceEditor();

    //
    // CKEDITOR
    //

    this.ckEditor = CKEDITOR.instances['ckeditor_' + this.langValue];

  }

  /*
   * openPreview
   * Deploys a modal to preview the content
   *
   */

  openPreview () {

    let content_html = null;

    if ( this.currentTab === 'rich')
      content_html = this.ckEditor.getData();
    else
      content_html = this.htmlEditor.getValue();

    let modal = document.getElementById('modal_preview');
    let modalContent = document.getElementById('modal_preview_content');

    modalContent.innerHTML = content_html;
    modal.classList.remove('hidden');
  }

  /*
   * changeTab
   * Triggered when user changes between RICH TEXT and HTML
   * tabs. It updates the proper editor.
   */

  changeTab (event) {
    if ( this.currentTab === 'rich' ) {
      // Current state HTML
      this.htmlEditor.session.setValue(this.ckEditor.getData());
      this.currentTab = 'html';
    }
    else {
      // Current state RICH TEXT
      this.ckEditor.setData(this.htmlEditor.getValue());
      this.currentTab = 'rich';
    }
  }

  _loadAceEditor () {

    //
    // Content HTML editor
    //

    ace.config.set('basePath', '/js');
    ace.config.set('modePath', '/js');
    ace.config.set('themePath', '/js');
    ace.config.set('workerPath','/js');

    this.htmlEditor = ace.edit('editor-' + this.langValue);

    this.htmlEditor.session.setMode('ace/mode/html');
    this.htmlEditor.setTheme('ace/theme/nord_dark');
    this.htmlEditor.session.setUseWorker('ace/worker/html');

    document.getElementById('editor-' + this.langValue).style.fontSize = '15px';

    this.htmlEditor.session.on('change', (delta) => {
      //let content = document.getElementById('page_content_' + this.langValue);
      //content.value = this.htmlEditor.getValue();
      this.ckEditor.setData(this.ckEditor.getData());
    }).bind(this);
  }
}
