import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  connect() {
  }

  rate(event) {
    let rating = event.target.dataset.value;
    let model = event.target.dataset.model;
    let index = event.target.dataset.index;
    const url = `/bikes_rating/rate?rating=${rating}&model_id=${model}&index=${index}`;

    Turbo.visit(url, { action: 'replace' });
  }

}