import { Controller } from '@hotwired/stimulus';

/*
 * Avatar Edition controller
 * Let to create a dropzone zone to
 * upload and preview files.
 *
 */

export default class extends Controller {

	static targets = [
	 	'avatar',
	 	'dropzoneMsg',
	 	'removeAvatar'
  ];

	connect () {}

	removeAvatar () {
		this.avatarTarget.remove();
		this.dropzoneMsgTarget.classList.remove('border-l-2');
		this.removeAvatarTarget.value = true;
	}

}