import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";

const countries = {
  Germany: "DE",
  Austria: "AT",
  Switzerland: "CH",
  Sweden: "SE",
  Norway: "NO",
  Finland: "FI",
  Netherlands: "NL",
  Belgium: "BE",
  Denmark: "DK",
  "United Kingdom": "GB",
  Spain: "ES",
  Portugal: "PT",
  Italy: "IT",
  France: "FR",
  Afghanistan: "AF",
  "Aland Islands": "AX",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Azerbaijan: "AZ",
  Bahamas: "BS",
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO",
  "Bonaire, Saint Eustatius and Saba": "BQ",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  "Bouvet Island": "BV",
  Brazil: "BR",
  "British Indian Ocean Territory": "IO",
  "British Virgin Islands": "VG",
  Brunei: "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cape Verde": "CV",
  "Cayman Islands": "KY",
  "Central African Republic": "CF",
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  "Christmas Island": "CX",
  "Cocos Islands": "CC",
  Colombia: "CO",
  Comoros: "KM",
  "Cook Islands": "CK",
  "Costa Rica": "CR",
  Croatia: "HR",
  Cuba: "CU",
  Curacao: "CW",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  "Democratic Republic of the Congo": "CD",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO",
  "East Timor": "TL",
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  Ethiopia: "ET",
  "Falkland Islands": "FK",
  "Faroe Islands": "FO",
  Fiji: "FJ",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF",
  Gabon: "GA",
  Gambia: "GM",
  Georgia: "GE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  "Heard Island and McDonald Islands": "HM",
  Honduras: "HN",
  "Hong Kong": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iran: "IR",
  Iraq: "IQ",
  Ireland: "IE",
  "Isle of Man": "IM",
  Israel: "IL",
  "Ivory Coast": "CI",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  Kosovo: "XK",
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  Laos: "LA",
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Macao: "MO",
  Macedonia: "MK",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH",
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  Micronesia: "FM",
  Moldova: "MD",
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE",
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk Island": "NF",
  "North Korea": "KP",
  "Northern Mariana Islands": "MP",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  "Palestinian Territory": "PS",
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH",
  Pitcairn: "PN",
  Poland: "PL",
  "Puerto Rico": "PR",
  Qatar: "QA",
  "Republic of the Congo": "CG",
  Reunion: "RE",
  Romania: "RO",
  Russia: "RU",
  Rwanda: "RW",
  "Saint Barthelemy": "BL",
  "Saint Helena": "SH",
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin": "MF",
  "Saint Pierre and Miquelon": "PM",
  "Saint Vincent and the Grenadines": "VC",
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome and Principe": "ST",
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  "Sint Maarten": "SX",
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Georgia and the South Sandwich Islands": "GS",
  "South Korea": "KR",
  "South Sudan": "SS",
  "Sri Lanka": "LK",
  Sudan: "SD",
  Suriname: "SR",
  "Svalbard and Jan Mayen": "SJ",
  Swaziland: "SZ",
  Syria: "SY",
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ",
  Thailand: "TH",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad and Tobago": "TT",
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks and Caicos Islands": "TC",
  Tuvalu: "TV",
  "U.S. Virgin Islands": "VI",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE",
  "United States": "US",
  "United States Minor Outlying Islands": "UM",
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  Vatican: "VA",
  Venezuela: "VE",
  Vietnam: "VN",
  "Wallis and Futuna": "WF",
  "Western Sahara": "EH",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW",
};
const mobiles = [
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Aland Islands",
    dial_code: "+358",
    code: "AX",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1264",
    code: "AI",
  },
  {
    name: "Antarctica",
    dial_code: "+672",
    code: "AQ",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Congo, The Democratic Republic of the Congo",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+357",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Jamaica",
    dial_code: "+1876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+77",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of South Korea",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Laos",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1670",
    code: "MP",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Reunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Saint Barthelemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1784",
    code: "VC",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Sudan",
    dial_code: "+211",
    code: "SS",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Vietnam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1340",
    code: "VI",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
];

export default class extends Controller {

  static targets = ["locations"];
  static values = { basket: String };

  connect() {

    this.updateBasket();
    
    var urlParams;
    (window.onpopstate = function () {
      var match,
        pl = /\+/g, // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) {
          return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);

      urlParams = {};
      while ((match = search.exec(query)))
        urlParams[decode(match[1])] = decode(match[2]);
    })();

    if (urlParams.model_type) {
      document.getElementById(`type-${this.basketValue}`).value =
        urlParams.model_type;
    }

    if (urlParams.models && urlParams.models.length > 0) {
      const reset = document.getElementById(`reset-${this.basketValue}`);
      if (reset) reset.classList.remove("hidden");
    }
    let plocId = document.getElementById(`plocId-${this.basketValue}`);
    if (urlParams.plocId && plocId) {
      plocId.value = urlParams.plocId;
      let flag = false;
      let locations = this.locationsTarget;
      for (var i = 1; i < locations.options.length; i++) {
        locations.options[i].classList.remove("hidden");
        if (plocId.value && locations.options[i].dataset.island != plocId.value)
          locations.options[i].classList.add("hidden");
      }
    } else {
      let query = window.location.pathname.split("/");
      let island = false;
      if (plocId) {
        query.forEach((element) => {
          switch (element) {
            case "gran-canaria":
              island = true;
              plocId.value = "1";
              break;
            case "tenerife":
              island = true;
              plocId.value = "2";
              break;
            case "lanzarote":
              island = true;
              plocId.value = "3";
              break;
          }
        });
        if (!island) {
          plocId.value = 1;
        }
      }
    }
    if (urlParams.height) {
      document.getElementById(`height-${this.basketValue}`).value =
        urlParams.height;
    }
    let active = document.getElementsByClassName("filter-type bg-black")[0];
    if (active) {
      active.classList.remove("bg-black");
      active.classList.add("bg-gray-300");
      let types = document.getElementsByClassName("filter-type");
      for (let i = 0; i < types.length; i++) {
        if (urlParams.model_type === types[i].dataset.type) {
          types[i].classList.remove("bg-gray-300");
          types[i].classList.add("bg-black");
        }
      }
    }
    let selected = false;
    let select = document.getElementById(`country-${this.basketValue}`);
    if (select) {
      for (var key in countries) {
        let option = document.createElement("option");
        option.text = key;
        option.value = countries[key];
        if (option.value === "ES") {
          option.id = "es";
        }
        if (select.dataset.country === countries[key]) {
          option.selected = "selected";
          selected = true;
        }
        select.appendChild(option);
      } /*
      if ( !selected ) {
        let spain = document.getElementById('es');
        spain.setAttribute('selected', 'selected');
      }*/
    }
    let select2 = document.getElementById(`prefix-${this.basketValue}`);
    if (select2) {
      selected = false;
      mobiles.forEach((prefix) => {
        let option = document.createElement("option");
        option.text = prefix.dial_code + " " + prefix.name;
        option.value = prefix.dial_code;
        if (prefix.code === "ES") {
          option.id = "es2";
        }
        if (select && select.dataset.country === prefix.code) {
          option.selected = "selected";
          selected = true;
        }
        select2.appendChild(option);
      });
    }

    let layer = document.getElementById(`bikes-booking-${this.basketValue}`);
    if (layer) this.bikes_layer();

    let bike = document.getElementById(`single-bike-${this.basketValue}`);
    if (bike) this.single_bike_layer(bike);

    let anchor = document.getElementById(`main-filters-${this.basketValue}`);
    let filterTagsMobile = document.getElementById(
      `filterTagsMobile-${this.basketValue}`
    );
    if (filterTagsMobile) {
      document.addEventListener("scroll", (event) => {
        if (anchor.offsetTop + 278 <= window.scrollY)
          filterTagsMobile.classList.add("fixed-filters");
        else filterTagsMobile.classList.remove("fixed-filters");
      });
    }

    layer = null;
    layer = document.getElementById(`cart-${this.basketValue}`);
    if (layer) document.addEventListener("priced", this.cart_layer);
  }

  removeFromCart(event) {
    if (document.getElementById("price_spinner")) document.getElementById("price_spinner").classList.remove("hidden");
    let basket = this.basketValue;
    document.getElementById(`spinner-${basket}`).classList.remove("hidden");
    let cancel = "";
    let tmp;
    let path = event.path || (event.composedPath && event.composedPath());
    path.forEach((element) => {
      if (element.dataset && element.dataset.cancel) {
        cancel = element.dataset.cancel;
        tmp = JSON.parse(element.dataset.item);
      }
    });
    if (cancel.length > 0) {
      let items;
      if (tmp.modelId) {
        items = [
          {
            item_id: tmp.modelId.toString(),
            item_name: tmp.modelName,
            item_brand: tmp.manufacturer,
            item_category: tmp.category,
            location_id: tmp.plocId,
            price: parseFloat(tmp.pricePerUnit),
            quantity: tmp.unitCount,
          },
        ];
      } else {
        items = [
          {
            item_id: tmp.tourId.toString(),
            item_name: tmp.details.tourName,
            item_category: tmp.details.categoryName,
            location_id: tmp.details.locId,
            price: parseFloat(tmp.pricePerUnit),
            quantity: 1,
          },
        ];
      }
      let url = `/agents/remove_from_basket?basket=${basket}&res_id=${cancel}`;
      fetch(url).then((data) => {
        this.removeFromCart_layer(parseFloat(tmp.priceTotal), items);
        removeBasketFromSession(this.basketValue);
        this.updateBasket();
        this.updateBasketHeader();
        window.location.reload();
      });

      function removeBasketFromSession(value) {
        let storedBasket = sessionStorage.getItem("baskets");
        let basketArray = storedBasket ? JSON.parse(storedBasket) : [];
        let indexToRemove = basketArray.indexOf(parseInt(value));
        if (indexToRemove !== -1) {
          basketArray.splice(indexToRemove, 1);
          let updatedBasket = JSON.stringify(basketArray);
          sessionStorage.setItem("baskets", updatedBasket);
        }
      }
    }
  }

  toggleExtra(event) {
    if (document.getElementById("price_spinner")) document.getElementById("price_spinner").classList.remove("hidden");
    let lang = "en";
    let basket = this.basketValue;
    const extra = event.target;
    const cancel = extra.dataset.cancel;
    let resId = extra.dataset.bike;
    let tmp = JSON.parse(extra.dataset.item);
    let add;

    if (extra.checked) {
      add = true;
      var url = `/booking_process/add_extra_to_bike?basket=${basket}&res_id=${resId}&prod_id=${extra.value}&lang=${lang}`;
    } else {
      add = false;
      var url = `/booking_process/remove_from_basket?basket=${basket}&res_id=${cancel}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let items = [
          {
            item_id: tmp.prodId.toString(),
            item_name: tmp.title,
            currency: "EUR",
            item_category: tmp.prodType,
            price: parseFloat(tmp.price),
          },
        ];
        if (add) this.addToCart_layer(parseFloat(tmp.price), items);
        else this.removeFromCart_layer(parseFloat(tmp.price), items);
        this.updateBasket();
        if (data && data.resId) event.target.dataset.cancel = data.resId;
        else delete event.target.dataset.cancel;
      });
  }

  toggleTour(event) {
    if (document.getElementById("price_spinner")) document.getElementById("price_spinner").classList.remove("hidden");
    let basket = event.target.dataset.basket
      ? event.target.dataset.basket
      : this.basketValue;
    const tour = event.target;
    const bike = tour.dataset.bike;
    var date = tour.dataset.date;
    const cancel = tour.dataset.cancel;
    let tours = document.querySelectorAll(`.tour-${bike}`);
    let tmp = JSON.parse(tour.dataset.item);
    let add;

    if (tour.checked) {
      tours.forEach((element) => {
        if (element.value != tour.value && date == element.dataset.date)
          element.setAttribute("disabled", "disabled");
      });
      add = true;
      var url = `/booking_process/add_tour_to_basket?basket=${basket}&tour=${tour.value}&bpr=${tour.dataset.bpr}&res=${bike}&loc=${tour.dataset.loc}&date=${date}`;
    } else {
      tours.forEach((element) => {
        if (element.value != tour.value && date == element.dataset.date)
          element.removeAttribute("disabled");
      });
      add = false;
      var url = `/booking_process/remove_from_basket?basket=${basket}&res_id=${cancel}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let items = [
          {
            item_id: tmp.tourId.toString(),
            item_name: tmp.details.tourName,
            item_category: tmp.details.categoryName,
            location_id: tmp.details.locId,
            price: parseFloat(tmp.price),
            quantity: 1,
          },
        ];
        if (add) this.addToCart_layer(parseFloat(tmp.price), items);
        else this.removeFromCart_layer(parseFloat(tmp.price), items);
        this.updateBasket();
        if (data && data.resId) event.target.dataset.cancel = data.resId;
        else delete event.target.dataset.cancel;
      });
  }

  updateBasket() {
    let basket = this.basketValue;
    if (document.getElementById(`price-spinner-${basket}`))
      document.getElementById(`price-spinner-${basket}`).classList.remove("hidden");
    if (basket) {
      const url = `/booking_process/get_basket/${basket}`;
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .catch((error) => {
          console.error(error);
        })
        .then((data) => {
          let unavailable = document.getElementById(
            `unavailable-${this.basketValue}`
          );
          if (unavailable) unavailable.classList.add("hidden");
          let price = 0;
          let discount = 0;
          let sum = 0;
          //const singleDiscount = document.getElementById(`discount-${basket}`)
          if (data) {
            data.OrderPosition.forEach((element) => {
              price = price + element.pricePerUnit;
              if (element.pricePerUnit < 0) {
                discount = discount + Math.abs(element.pricePerUnit);
                //if (singleDiscount) singleDiscount.innerHTML = discount.toFixed(2)
              }
            });
          }

          //if (singleDiscount && singleDiscount.innerHTML != "") singleDiscount.parentNode.classList.remove('hidden') 

          const supertotal = document.getElementById(`supertotal-${basket}`);
          const previousPrice = document.getElementById(`price-without-discount-${basket}`)
          const finalPrice = document.getElementById(`price-with-discount-${basket}`)
          sum = price + discount;
          let sumDisplay = sum.toFixed(2).replace(".", ",");
          let priceDisplay = price.toFixed(2).replace(".", ",");
          let discountDisplay = discount.toFixed(2).replace(".", ",");
          if (discount > 0 && previousPrice) {
            previousPrice.innerHTML = `${sumDisplay}€`
            previousPrice.classList.remove('hidden') 
          }
          if (finalPrice) finalPrice.innerHTML = priceDisplay
          if (supertotal) supertotal.value = priceDisplay;
          if (document.getElementById(`price-spinner-${basket}`))
            document.getElementById(`price-spinner-${basket}`).classList.add("hidden");

          let total;
          let interval = setInterval(() => {
            total = document.getElementById(`total-${basket}`);
            if (total) {
              clearInterval(interval);
              setPrice();
              document.getElementById("prices-section").classList.remove("hidden");
              document.getElementById(`spinner-${basket}`) ?
                document.getElementById(`spinner-${basket}`).classList.add("hidden") :
                document.getElementById('spinner').classList.add("hidden");
            }
          }, 1000);

          function setPrice() {
            total.value = price;
            let totalBaskets = document.getElementById(`total-baskets`);
            const totalInputs = document.querySelectorAll(".total-input");
            let totalSum = 0;
            totalInputs.forEach(function (input) {
              totalSum += parseFloat(input.value);
            });
            totalBaskets.innerHTML = totalSum;

            const event = document.createEvent("Event");
            event.initEvent("priced", true, true);
            document.dispatchEvent(event);
            if (document.getElementById("price_spinner")) document.getElementById("price_spinner").classList.add("hidden");
          }
        });
    }
  }

  updateBasketHeader() {
    let baskets = sessionStorage.getItem("baskets");
    let count = baskets ? JSON.parse(baskets).length : 0;
    document.getElementById(`cart1`).innerHTML = count;
    document.getElementById(`cart2`).innerHTML = count;
  }

  changeDriver(event) {
    let path = event.path || (event.composedPath && event.composedPath());
    path.forEach((element) => {
      if (element.dataset && element.dataset.bprid) {
        let nameInput = document.getElementById("name");
        let surnameInput = document.getElementById("surname");
        let emailInput = document.getElementById("email");
        let phonelInput = document.getElementById("phone");
        let saveDriver = document.getElementById("save-driver");
        saveDriver.dataset.bprid = element.dataset.bprid;
        const url = `/agents/get_customer?bprId=${element.dataset.bprid}`;
        document.getElementById('spinner').classList.remove('hidden');
        fetch(url)
        .then((response) => response.json())
        .then((data) => {
          nameInput.value = data.firstname;
          surnameInput.value = data.name;
          emailInput.value = data.email;
          phonelInput.value = data.mobil;
          document.getElementById('spinner').classList.add('hidden');
          document.getElementById('driver-modal').classList.remove('hidden');
        })
        .catch(function (error) {
          document.getElementById('spinner').classList.add('hidden');
          console.log("Hubo un problema con la petición:" + error.message);
        });;
      }
    });
  }

  closeDriver() {
    document.getElementById('driver-modal').classList.add('hidden');
    let nameInput = document.getElementById("name");
    let surnameInput = document.getElementById("surname");
    let saveDriver = document.getElementById("save-driver");
    nameInput.value = '';
    surnameInput.value = '';
    saveDriver.dataset.bprid = '';
  }

  saveDriver(event) {
    const nameInput = document.getElementById("name").value;
    const surnameInput = document.getElementById("surname").value;
    const emailInput = document.getElementById("email").value;
    const phoneInput = document.getElementById("phone").value;
    const url = `/agents/change_driver?bprId=${event.target.dataset.bprid}&name=${nameInput}&surname=${surnameInput}&email=${emailInput}&phone=${phoneInput}`;
    document.getElementById('spinner').classList.remove('hidden');
    document.getElementById('driver-modal').classList.add('hidden');
    fetch(url).then((data) => {
      window.location.reload();
    });
  }

  paySingleBooking(event) {
    const spinner = document.getElementById("spinner")
    if(spinner) spinner.classList.remove("hidden");
    const e = event.target;
    const basket = e.dataset.basket;
    let url = `/booking_process/create-payment?basket=${basket}`;

    fetch(url)
      .then((response) => {
        if (response.redirected) {
          window.location.href = response.url;
          return;
        }
        return response.json();
      })
      .then((data) => {
        if(spinner) spinner.classList.add("hidden");
        if (data && data.ref) {
          window.location = `https://www.vivapayments.com/web/checkout?ref=${data.ref}`;
        } else {
          alert("An error has occurred with the payment platform. Please try again.");
        }
      })
      .catch((error) => {
        if(spinner) spinner.classList.add("hidden");
        console.error('Error:', error);
        alert("An error has occurred with the payment platform. Please try again.");
      });
  }

  sendConfirmation() {
    document.getElementById("spinner").classList.remove("hidden");
    let e = event.target
    const basket = e.dataset.basket
    let url = `/agents/send_confirmation_email/${basket}`
    fetch(url)
    .then(() => {
      location.reload();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  sendValidation(event) {
    document.getElementById("spinner").classList.remove("hidden");
    let e = event.target
    const basket = e.dataset.basket
    let url = `/agents/send_validation_email/${basket}`
    fetch(url)
    .then(() => {
      location.reload();
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  //
  // Layers functions
  //

  single_bike_layer(elem) {
    let bike;
    if (elem && elem.dataset) {
      bike = JSON.parse(elem.dataset.bike);
      let price = parseFloat(elem.dataset.price);
      let tmp = document.getElementById("user-info");
      let user = {};
      if (tmp) {
        tmp = tmp.dataset;
        user = {
          email: tmp.email,
          first_name: tmp.first_name,
          last_name: tmp.last_name,
          phone_number: tmp.phone_number,
          street: tmp.street,
          city: tmp.city,
          region: tmp.region,
          postal_code: tmp.postal_code,
          country: tmp.country,
        };
      }

      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: "view_item",
        ecommerce: {
          item_list_name: "Bikes",
          items: [
            {
              item_id: bike.model_id.toString(),
              item_name: bike.model_title,
              currency: "EUR",
              index: 0,
              item_brand: bike.manufacturer,
              item_category: bike.product_group_name,
              price: parseFloat(price),
              quantity: 1,
            },
          ],
          enhanced_conversion_data: user,
        },
      });
      console.log("DATALAYER - view_item", dataLayer);
    }
  }

  bikes_layer() {
    let bikesData = [];
    let bikes = document.getElementsByClassName("bike-info");
    for (let i = 0; i < bikes.length; i++) {
      let bike = JSON.parse(bikes[i].dataset.bike);
      bikesData.push({
        item_id: bike.model_id.toString(),
        item_name: bike.model_title,
        currency: "EUR",
        index: i,
        item_brand: bike.manufacturer,
        item_category: bike.product_group_name,
        price: parseFloat(bikes[i].dataset.price),
        quantity: 1,
      });
    }
    let tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_name: "Bikes",
        items: bikesData,
        enhanced_conversion_data: user,
      },
    });
    console.log("DATALAYER - view_item_list", dataLayer);
  }

  cart_layer() {
    document.removeEventListener("priced", this.cart_layer);
    const items = document.getElementsByClassName("cart-item");
    const options = document.querySelectorAll("input.extra:checked");
    const total = document.getElementById(`total-${this.basketValue}`);
    let tmp;
    let itemsData = [];
    for (let i = 0; i < items.length; i++) {
      let item = items[i].dataset;
      tmp = JSON.parse(item.value);
      if (item.type === "bike") {
        itemsData.push({
          item_id: tmp.modelId.toString(),
          item_name: tmp.modelName,
          currency: "EUR",
          index: i,
          item_brand: tmp.manufacturer,
          item_category: tmp.category,
          price: parseFloat(tmp.pricePerUnit),
          location_id: tmp.plocId.toString(),
          quantity: tmp.unitCount,
        });
      }
      if (item.type === "tour") {
        itemsData.push({
          item_id: tmp.tourId.toString(),
          item_name: tmp.details.tourName,
          currency: "EUR",
          index: i,
          item_category: tmp.details.categoryName,
          price: parseFloat(tmp.pricePerUnit),
          location_id: tmp.details.locId.toString(),
          quantity: tmp.unitCount,
        });
      }
    }
    for (let i = 0; i < options.length; i++) {
      let item = options[i].dataset;
      tmp = JSON.parse(item.item);
      itemsData.push({
        item_id: tmp.prodId.toString(),
        item_name: tmp.title,
        currency: "EUR",
        index: i,
        item_category: tmp.prodType,
        price: parseFloat(tmp.price),
      });
    }
    tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    let session = JSON.stringify({
      currency: "EUR",
      value: parseFloat(total.innerText),
      items: itemsData,
      enhanced_conversion_data: user,
    });
    sessionStorage.setItem("cart_layer", session);
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "view_cart",
      ecommerce: { session },
    });
    console.log("DATALAYER - view_cart", dataLayer);
  }

  addToCart_layer(total, items) {
    let tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "EUR",
        value: parseFloat(total),
        items: items,
        enhanced_conversion_data: user,
      },
    });
    console.log("DATALAYER - add_to_cart", dataLayer);
  }

  removeFromCart_layer(total, items) {
    let tmp = document.getElementById("user-info");
    let user = {};
    if (tmp) {
      tmp = tmp.dataset;
      user = {
        email: tmp.email,
        first_name: tmp.first_name,
        last_name: tmp.last_name,
        phone_number: tmp.phone_number,
        street: tmp.street,
        city: tmp.city,
        region: tmp.region,
        postal_code: tmp.postal_code,
        country: tmp.country,
      };
    }
    dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "EUR",
        value: parseFloat(total),
        items: items,
        enhanced_conversion_data: user,
      },
    });
    console.log("DATALAYER - remove_from_cart", dataLayer);
  }
}
