import { Controller } from "@hotwired/stimulus"
import Glide from '@glidejs/glide'

export default class extends Controller {

  connect() {
    
    let glide = new Glide('.glide', {
      type: 'carousel',
      //autoplay: 4000,
      focusAt: 'center',
      startAt: 1,
      perView: 3,
      breakpoints: {
        767: {
          focusAt: "center",
          perView: 1,
        },
      }
    });

    glide.mount();

  }

}