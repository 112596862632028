// app/javascript/helpers/index.js
import { Turbo } from "@hotwired/turbo-rails";

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}


/**
 * randomString
 * Generates a new random string according
 * to the length passed by param
 *
 * @param length {number} - Random string length
 */

export function randomString (length) {

  if ( typeof length !== 'number' ) {
    console.error('Length must be an integer');
    return;
  }

  let result = '';
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];

  return result;
}


/**
 * turbo_fetch
 * Makes a fetch with turbo format
 *
 * @param url {string} - url for the fetch
 */

/*export function turbo_fetch (url) {
  fetch(url, {
      headers: {
      Accept: "text/vnd.turbo-stream.html",
      },
  })
  .then(r => r.text())
  .then(html => Turbo.renderStreamMessage(html))
  .then(data => {
    const event = document.createEvent('Event');
    event.initEvent('framed', true, true);
    document.dispatchEvent(event);
  })
}*/

export function turbo_fetch(url) {
  return fetch(url, {
    headers: {
      Accept: "text/vnd.turbo-stream.html",
    },
  })
    .then((response) => response.text())
    .then((html) => Turbo.renderStreamMessage(html))
    .then((data) => {
      const event = new Event("framed", { bubbles: true, cancelable: true });
      document.dispatchEvent(event);
      return data;
    })
    .catch((error) => {
      console.error("Error during turbo_fetch:", error);
      throw error;
    });
}
