import { Controller } from '@hotwired/stimulus';
import "ace-builds";

/*
 * Page CMS creation controller
 *
 */

export default class extends Controller {

	static targets = [
    'hiddenSeoJSON',
    'hiddenSeoJsonEN',
    'hiddenSeoJsonDE',
    'seoURL',
    'language'
  ];

  static values = {
    host: String,
    action: String,
    url: String,
  };

  seoJSONEditor = null;
  seoJSONEditorEN = null;
  seoJSONEditorDE = null;

	connect () {

    //
    // Load ACE editor for SEO
    //


    this._initAce();
    this._loadAceEditorForSEO();
    this._loadAceEditorForSeoEN();
    this._loadAceEditorForSeoDE();
  
    if ( this.actionValue === 'create' ) {
      this.seoURLTarget.innerHTML = `
        <span class="text-sm text-rose-400 font-semibold">No available yet</span><br />
        <span class="text-sm text-rose-400">Just type something on the spanish slug field</span>
      `;
    }
    else {
      // Updating seo URL
      this.seoURLTarget.innerHTML = `<span class="text-lime-500 font-semibold text-sm">${this.urlValue}</span>`;
    }
  }

  /*
   * closePreview
   * Closes the preview modal
   *
   */

  closePreview () {
    let modal = document.getElementById('modal_preview');
    
    modal.classList.add('hidden');
  }


  updateSeoURL (event) {
    let baseUrl = null;

    if ( event.target.value ) {
      baseUrl = event.target.value ? `${this.hostValue}/${this._string_parameterize(event.target.value)}` : '';
      this.seoURLTarget.innerHTML = `<span class="text-lime-500 font-semibold text-sm">${baseUrl}</span>`;
    }
    else {
      this.seoURLTarget.innerHTML = `<span class="text-sm text-rose-400 font-semibold">No available</span>`;
    }
  }

  _string_parameterize (str) {
    return str.trim().toLowerCase().replace(/[^a-zA-Z0-9/ -]/, '').replace(/\s/g, '-');
  };


  _initAce () {
    ace.config.set('basePath', '/js');
    ace.config.set('modePath', '/js');
    ace.config.set('themePath', '/js');
    ace.config.set('workerPath','/js');
  }

  _loadAceEditorForSEO () {

    //
    // JSON/LD editor ES
    //

    this.seoJSONEditor = ace.edit('editorSeoJSON');
    this.seoJSONEditor.session.setMode('ace/mode/json');
    this.seoJSONEditor.setTheme('ace/theme/nord_dark');
    this.seoJSONEditor.session.setUseWorker('ace/worker/json');
    this.seoJSONEditor.session.on('change', this.seoJSONEditorOnChange);

    document.getElementById('editorSeoJSON').style.fontSize = '15px';
  }

  _loadAceEditorForSeoEN () {

    //
    // JSON/LD editor EN
    //

    this.seoJSONEditorEN = ace.edit('editorSeoJsonEN');
    this.seoJSONEditorEN.session.setMode('ace/mode/json');
    this.seoJSONEditorEN.setTheme('ace/theme/nord_dark');
    this.seoJSONEditorEN.session.setUseWorker('ace/worker/json');
    this.seoJSONEditorEN.session.on('change', this.seoJSONEditorOnChangeEN);

    document.getElementById('editorSeoJsonEN').style.fontSize = '15px';
  }

  _loadAceEditorForSeoDE () {

    //
    // JSON/LD editor EN
    //

    this.seoJSONEditorDE = ace.edit('editorSeoJsonDE');
    this.seoJSONEditorDE.session.setMode('ace/mode/json');
    this.seoJSONEditorDE.setTheme('ace/theme/nord_dark');
    this.seoJSONEditorDE.session.setUseWorker('ace/worker/json');
    this.seoJSONEditorDE.session.on('change', this.seoJSONEditorOnChangeDE);

    document.getElementById('editorSeoJsonDE').style.fontSize = '15px';
  }


  //
  // Events Callbacks
  //

  seoJSONEditorOnChange () {
    this.hiddenSeoJSONTarget.value = this.seoJSONEditor.getValue();
  }

  seoJSONEditorOnChangeEN () {
    this.hiddenSeoJsonENTarget.value = this.seoJSONEditorEN.getValue();
  }

  seoJSONEditorOnChangeDE () {
    this.hiddenSeoJsonDETarget.value = this.seoJSONEditorDE.getValue();
  }

  preSubmit (event) {
    event.preventDefault();
    let rich = document.getElementsByClassName('richtext');
    console.log(rich);
    Array.from(rich).forEach(element => {
      element.click();
    });
    setTimeout(() => {
      event.target.form.requestSubmit()
    }, 100);
  }

}