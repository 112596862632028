import Dropzone from 'dropzone';
import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';
import { getMetaValue, findElement, removeElement, insertAfter } from './../helpers';

/*
 * Dropzone controller
 * Let to create a dropzone zone to
 * upload and preview files.
 *
 */

export default class extends Controller {

  previewNode = null;

  static targets = [
    'input',
    'dropzone',
    'dropzoneMsg',
    'dropzoneError',
    'dropzonePreview',
    'dropzoneProgress'
  ];

  connect() {

    //
    // Getting the preview template and removing it from
    // DOM to hide it.
    //

    this.previewNode = document.querySelector('#previewTemplate');
    //let previewTemplate = previewNode.innerHTML;
    //this.previewNode.parentNode.removeChild(this.previewNode);

    this.dropZone = createDropZone(this, this.previewNode.innerHTML);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console

    //
    // Hiding error div
    //

    this.dropzoneErrorTarget.style.display = 'none';

  }

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  bindEvents() {

    this.dropZone.on('drop', file => {

      //
      // If maxFiles === 1 we replace the
      // already uploaded file if it exists.
      //

      if ( this.maxFiles === '1' && this.dropZone.getAcceptedFiles().length > 0 ) {
        this.dropZone.removeFile(this.dropZone.getAcceptedFiles()[0]);
      }

      //
      // We also check if there's an
      // avatar to edit. If so, we remove it.
      //

      let avatarToEdit = document.querySelector('.avatar-to-edit')

      if ( avatarToEdit ) avatarToEdit.remove();

      //
      // Removing previous error messages
      //

      // $('.dz-error-custom').hide();
      this.dropzoneErrorTarget.style.display = 'none';
    });

    this.dropZone.on('addedfile', file => {
      
      this.dropzoneMsgTarget.style.display = 'none';
      this.dropzoneErrorTarget.style.display = 'none';

      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on('removedfile', file => {
      file.controller && removeElement(file.controller.hiddenInput);
      //$('.dropzone').show();
      this.dropzoneTarget.style.display = 'block';

      //$('.dropzone-msg').show();
      this.dropzoneMsgTarget.style.display = 'block';
    });

    this.dropZone.on('queuecomplete', progress => {
      setTimeout(() => {
        // $('#previews .progress').addClass('animated fadeOut');
        let preview = document.querySelector('.dz-preview');
        
        if ( preview )
          preview.querySelector('.progress').style.display = 'none';

      }, 1000);
    });

    this.dropZone.on('canceled', file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on('error', (file, err) => {
      
      document.querySelector('.dz-preview').remove();

      this.dropzoneErrorTarget.innerHTML = err;
      this.dropzoneErrorTarget.style.display = 'block';
      //$('.dz-preview').remove();
      //$('.dz-error-custom').html(err);
      //$('.dz-error-custom').show();
    });
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') };
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url');
  }

  get maxFiles() {
    return this.data.get('maxFiles') || 1;
  }

  get maxFileSize() {
    return this.data.get('maxFileSize') || 256;
  }

  get acceptedFiles() {
    return this.data.get('acceptedFiles');
  }

  get addRemoveLinks() {
    return this.data.get('addRemoveLinks') || false;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener('progress', event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    this.source.dropzoneProgressTarget.firstElementChild.style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit('processing', this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit('error', this.file, error);
    this.source.dropZone.emit('complete', this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit('success', this.file);
    this.source.dropZone.emit('complete', this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller, previewTemplate) {

  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: Boolean(controller.addRemoveLinks),
    autoQueue: false,
    previewTemplate: previewTemplate
  });
}