import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails";
import * as ActiveStorage from "@rails/activestorage";
import 'tw-elements';

import "./controllers";
import "./channels";
import "./helpers";
import "./helpers/data-confirm-tailwind-modal";

import Alpine from "alpinejs";
window.Alpine = Alpine;

document.addEventListener("DOMContentLoaded", function (event) {
  window.Alpine.start();
});

Rails.start()
ActiveStorage.start()

addEventListener("trix-attachment-add", event => {
  const { attachment, target } = event

  if (attachment.file) {
    const upload = new AttachmentUpload(attachment, target)
    upload.start()
  }
})