import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  
  static targets = ["locale"];

  connect() {
    let urlParams;
    let basket;
    let spinner = document.getElementById('pre_spinner');
    (window.onpopstate = function () {
      var match,
        pl = /\+/g, // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) {
          return decodeURIComponent(s.replace(pl, " "));
        },
        query = window.location.search.substring(1);

      urlParams = {};
      while ((match = search.exec(query)))
        urlParams[decode(match[1])] = decode(match[2]);
    })();
    if (urlParams.basket) {
      basket = urlParams.basket;
      sessionStorage.setItem("basket", basket);
      spinner.classList.add('hidden');
    }
    else {
      const lang = this.localeTarget.value;
      basket = sessionStorage.getItem("basket");
      const isle = document.getElementById("current-island");
      let island = "gran-canaria";
      switch (isle.innerText) {
        case "GRAN CANARIA":
          island = "gran-canaria";
          break;
        case "TENERIFE":
          island = "tenerife";
          break;
        case "LANZAROTE":
          island = "lanzarote";
          break;
        default:
          island = "gran-canaria";
      }
      window.location.href = `/${lang}/${island}/basket?basket=${basket}`;
    }
  }

}