import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  connect() {}

  rate(event) {
    let rating = event.target.dataset.value;
    let tour_id = event.target.dataset.tour;
    let index = event.target.dataset.index;
    const url = `/tours_rating/rate?rating=${rating}&tour_id=${tour_id}&index=${index}`;

    Turbo.visit(url, { action: 'replace' });
  }

}