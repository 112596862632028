import { Controller } from "@hotwired/stimulus"
import Glide from '@glidejs/glide'

export default class extends Controller {

  connect() {
    
    let glide = new Glide('#glide', {
      type: 'slider',
      //autoplay: 4000,
      focusAt: 'center',
      startAt: 1,
      perView: 3
    });

    glide.mount();

    let interval = setInterval(function() {
      let button = document.getElementById("shops-link");
      let island = document.getElementById("current-island");
      let lang = document.getElementById("current-lang");
      if (button) {
        clearInterval(interval);
        switch (island.innerText) {
          case 'GRAN CANARIA':
            island = 'gran-canaria';
            break;
          case 'TENERIFE':
            island = 'tenerife';
            break;
          case 'LANZAROTE':
            island = 'lanzarote';
            break;
          default:
            island = 'gran-canaria';
        }
        switch (lang.innerText) {
          case 'ENGLISH':
            lang = 'en';
            break;
          case 'ESPAÑOL':
            lang = 'es';
            break;
          case 'DEUTSCH':
            lang = 'de';
            break;
          default:
            lang = 'en';
        }
        button.href=`/${lang}/${island}/shops-service/shops`;
      }
    }, 100);

  }

}