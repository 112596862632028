import { Controller } from "@hotwired/stimulus"
import Glide from '@glidejs/glide'

export default class extends Controller {

  connect() {
    let query = window.location.pathname.split('/');
    let selector = document.getElementById('island');
    query.forEach(element => {
      switch (element) {
        case 'gran-canaria':
          selector.value = '1';
          break;
        case 'tenerife':
          selector.value = '2';
          break;
        case 'lanzarote':
          selector.value = '3';
          break;
      }
    });

    this.setCarousel();
  }

  toggleMain(event) {

    const item = event.target.id;

    let bikes = document.getElementById('bikes');
    let bikeType = document.getElementById('bike-type');
    let tours = document.getElementById('tours');
    let tourType = document.getElementById('tour-type');

    switch (item) {
      case 'bikes':
        bikes.classList.remove('border-white', 'selected');
        bikes.classList.add('bg-white', 'freemotion-border','freemotion-color', 'shadow', 'selected');
        tours.classList.remove('bg-white', 'freemotion-border','freemotion-color', 'shadow', 'selected');
        tours.classList.add('border-white');
        bikeType.classList.remove('hidden');
        tourType.classList.add('hidden');
        break;
      case 'tours':
      case 'default':
        tours.classList.remove('border-white');
        tours.classList.add('bg-white', 'freemotion-border','freemotion-color', 'shadow', 'selected');
        bikes.classList.remove('bg-white', 'freemotion-border','freemotion-color', 'shadow', 'selected');
        bikes.classList.add('border-white');
        bikeType.classList.add('hidden');
        tourType.classList.remove('hidden');
        break;
    }

  }

  start(event) {

    let selected = document.getElementsByClassName('selected')[0];

    let island = document.getElementById('island').value;
    let islandName = 'gran-canaria';
    switch (island) {
      case '1':
        islandName= 'gran-canaria';
        break;
      case '2':
        islandName= 'tenerife';
        break;
      case '3':
        islandName= 'lanzarote';
        break;
    }
    let lang = window.location.pathname.split('/')[1];
    if ( !lang || lang === '' ) {
      lang = 'en';
    }
    switch (selected.id) {
      case 'bikes':
        let bikeType = document.getElementById('bike-select').value;
        window.location.href = `/${lang}/${islandName}/bikes?model_type=${bikeType}&plocId=${island}`;
        break;
      case 'tours':
        let tourType = document.getElementById('tour-select').value;
        window.location.href = `/${lang}/${islandName}/tours?tour_type=${tourType}&plocId=${island}`;
        break;
    }
  }

  setCarousel() {
    const container = document.getElementById('carousel')
    let glide = new Glide('#carousel', {
      type: 'carousel',
      autoplay: 5000,
      focusAt: 'center',
      startAt: 1,
      perView: 1,
    });
    glide.mount();
  }

}