import { Turbo } from "@hotwired/turbo-rails";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let items = [];
    const tmp = document.getElementById('basket');
    const basket = JSON.parse(JSON.parse(tmp.dataset.basket));
    const order = basket.OrderPosition;
    let total = 0.0;
    let userInfo = document.getElementById('user-info');
    let user = {};
    if ( userInfo ) {
      userInfo = userInfo.dataset;
      user = {
        email: userInfo.email,
        first_name: userInfo.first_name,
        last_name: userInfo.last_name,
        phone_number: userInfo.phone_number,
        street: userInfo.street,
        city: userInfo.city,
        postal_code: userInfo.postal_code,
        country: userInfo.country
      }
    }
    for ( let i = 0; i < order.length; i++ ) {
      total += parseFloat(order[i].listPricePerUnit);
      items.push({
        item_id: order[i].navItemNo.toString(),
        item_name: order[i].unitDescription,
        currency: "EUR",
        discount: parseFloat(order[i].discountPercent),
        index: i,
        item_category: order[i].prodType,
        item_list_name: "Purchase",
        price: order[i].listPricePerUnit,
        quantity: order[i].unitCount
      });
    }
    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: basket.orderId.toString(),
        currency: "EUR",
        value: total,
        items: items,
        enhanced_conversion_data: user
      }
    });
    console.log('DATALAYER - purchase', dataLayer);
    document.getElementById('cart1').innerHTML = 0;
    sessionStorage.removeItem('basket');
  }
}