
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    let query = window.location.pathname.split('/');
    query.forEach(element => {
      switch (element) {
        case 'gran-canaria':
        case 'tenerife':
        case 'lanzarote':
          sessionStorage.setItem('island', element);
          return;
          break;
      }
   });
  }

}